
function notifyGlobal(txt, timeout, cls) {
    console.log(txt);
    var n = $("#global-notifier");
    console.log(n);
    notify(n, txt, timeout, cls);
};

function notifyGlobalError(txt, timeout) {
    if (!timeout)
        timeout = 3000;
    notifyGlobal(txt, timeout, 'n-alert');
};

function notifyGlobalSuccess(txt, timeout) {
    
    if (!timeout)
        timeout = 3000;
    notifyGlobal(txt, timeout, 'n-success');
};

function notifyGlobalWarning(txt, timeout) {
    if (!timeout)
        timeout = 3000;
    notifyGlobal(txt, timeout, 'n-warning');
};

function notify(n, txt, timeout, cls) {
    $(n).removeClass('.n-warning');
    $(n).removeClass('.n-alert');
    $(n).removeClass('.n-success');
    $(n).html(txt);
    //
    var newcls = 'n-success';
    //
    if (cls)
        newcls = cls;
    $(n).addClass(newcls);
    $(n).fadeIn('slow');
    var delay = 2000;
    if (timeout)
        delay = timeout;
    setTimeout(function () {
        $(n).fadeOut("slow");
    }, delay);
}