function CodexCart() {
    this.addToCartUrl = '';
    this.removeFromCartUrl = '';
    this.applyDotsUrl = '';
    this.removeDotsUrl = '';
    this.getCartLineUrl = '';
};


CodexCart.prototype.AddToCart = function (pid, qty, comment, file, packaging, variantCombination, onSuccess, onFail) {
    if (pid == undefined || qty == undefined )
        return;
    if (packaging == undefined || !packaging)
        packaging = 1;

    if (variantCombination == undefined || !variantCombination)
        variantCombination = null;
    $.ajax({
        type: "POST",
        url: this.addToCartUrl,
        contentType: "application/json; charset=utf-8",
        data:
            JSON.stringify({
                "productId": pid,                
                "quantity": qty,
                "comment": comment,
                "file": file,
                "packaging": packaging,
                "variantCombination": variantCombination
            }),
        dataType: "json",
        success: onSuccess,
        error: onFail
    });
};


CodexCart.prototype.RemoveFromCart = function (guid, onSuccess, onFail) {
    if (guid == undefined)
        return;
    $.ajax({
        type: "POST",
        url: this.removeFromCartUrl,
        contentType: "application/json; charset=utf-8",
        data:
        JSON.stringify({
            "guid": guid
        }),
        dataType: "json",
        success: onSuccess,
        error: onFail
    });
};


CodexCart.prototype.RefreshHeaderCart = function(cart){
    $("#cartHeaderAmount").text(cart.TotalToPayString);
    $("#cartHeaderProductCount").text(cart.ProductCount);
}


CodexCart.prototype.RefreshCartSummary = function (cartSummary) {
    var shippingCost = cartSummary.Shipping.CostString;
    var shippingName = cartSummary.Shipping.Name;
    //
    $("#shipping-name").text(shippingName);
    $("#shipping-cost").text(shippingCost);
    //
    var total = cartSummary.TotalToPayString;
    var tax = cartSummary.TaxString;
    var withTax = cartSummary.TotalWithTaxString;
    var discount = cartSummary.DiscountString;
    //
    $("#cart-summary-without-tax").text(withTax);
    $("#cart-summary-tax").text(tax);
   
    if (cartSummary.Discount > 0) 
        $(".cart-summary-discount-container").show();
    else 
        $(".cart-summary-discount-container").hide();
    //
    $("#cart-summary-discount").text(discount);
    $("#cart-summary-total").text(total);
}


CodexCart.prototype.DisplayHeaderCartSummary = function (getSummaryUrl) {

    $.ajax({
        type: "POST",
        url: getSummaryUrl,
        contentType: "application/json; charset=utf-8",
       
        dataType: "json",
        success: function (cart) {
            $("#cartHeaderAmount").text(cart.TotalToPayString);
            $("#cartHeaderProductCount").text(cart.ProductCount);
        },
        error: function (response) {

        }
    });
    var cart;
   
}


CodexCart.prototype.ApplyDots = function (onSuccess, onFail) {
    
    $.ajax({
        type: "POST",
        url: this.applyDotsUrl,
        contentType: "application/json; charset=utf-8",              
        success: onSuccess,
        error: onFail
    });
};



CodexCart.prototype.RemoveDots = function (onSuccess, onFail) {

    $.ajax({
        type: "POST",
        url: this.removeDotsUrl,
        contentType: "application/json; charset=utf-8",
        success: onSuccess,
        error: onFail
    });
};


CodexCart.prototype.GetCartLine = function (pid, onSuccess, onFail) {

    if (pid == undefined)
        return;
    $.ajax({
        type: "POST",
        url: this.getCartLineUrl,
        contentType: "application/json; charset=utf-8",
        data:
        JSON.stringify({
            "productId": pid
        }),
        dataType: "json",
        success: onSuccess,
        error: onFail
    });
};